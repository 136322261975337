// Shared Begins
.errorText {
  color: var(--red-border);
}

.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.marginLeft {
  margin-left: 0.5rem;
}

.marginBottom {
  margin-bottom: 10px;
}

.certificateField {
  height: 200px;

  &:not(:focus) {
    color: transparent !important;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  }
}

.noMarginLeft {
  margin-left: 0 !important;
}

.saveButton {
  width: fit-content !important;
  margin-right: 1rem;
}

.fieldMapping {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.paddingRight {
    padding-right: 5px;
  }

  .colHeader {
    text-align: left;

    &.left {
      width: 120px;
    }
  }

  .validation {
    align-items: flex-start;
  }
}

.fieldSelectorButton {
  width: 120px;
}

.stripeNumberField {
  width: 120px !important;
  margin-right: 5px;
}

.fieldText {
  min-width: 60px;
  max-width: 120px;
}

.labelLink {
  font-size: 12px;
  font-family: var(--font);
  color: var(--grey-70);
  font-weight: 500;
  padding: 4px 6px 3px 6px;
}

// Shared Ends

// IntegrationCard Begins
.integrationCard {
  width: 100%;
  height: 10rem;
  cursor: pointer;
  margin-bottom: 1rem;
  border: none;
}

.integrationCard.hover:hover,
.integrationCardActive {
  outline: 2px solid var(--prim-100);
  box-shadow: 0 1px 3px rgba(226, 98, 110, 0.76);
  border-radius: 4px;
}

.integrationCardBody {
  display: flex;
  align-items: center;
  height: 100%;
}

.integrationTitleText {
  font-weight: bold;
  font-size: 16px;
}

.integrationDescriptionText {
  text-align: left;
  color: var(--grey-70);
  margin: 0.375rem 0 0.5rem 0;
}

.logoContainer {
  width: 90%;
  padding-right: 0.8rem;

  img,
  svg {
    max-width: 100%;
    object-fit: contain;
  }
}

.integrationLogoColumn {
  width: 15%;
  text-align: center;
}

.integrationInfoColumn {
  width: 67%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.integrationStatusColumn {
  width: 18%;
  display: flex;
  justify-content: flex-end;
}

.connectedStatus,
.errorStatus,
.disconnectedStatus {
  padding: 0.5rem 0.375rem;
  width: max-content;
  font-size: 11px;
  color: var(--grey-80);
  box-sizing: border-box;
  border-radius: 5px;
}

.validatedField {
  display: flex;
  flex-direction: column;
}

.connectedStatus {
  background-color: var(--validation-pass-light);
  border: 1px solid var(--green-border);
}

.connectedStatus svg {
  height: 12px;
}

.errorStatus {
  background-color: var(--validation-error-light);
  border: 1px solid var(--red-border);
}

.disconnectedStatus {
  background-color: var(--grey-10);
  border: 1px solid var(--grey-30);
}

// IntegrationCard Ends

// IntegrationsSidebar Begins
.sidebarContainer {
  word-wrap: break-word;
  background-clip: initial;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.176);
  border-radius: 5px;
  color: var(--body-color);
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;

  width: 450px;
}

.sidebarSection {
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
}

.sidebarSection > form {
  width: 100%;
}

.horizontalDivider {
  border-bottom: 1px solid var(--grey-30);
  margin: 1rem 0;
}

.connectButton {
  background-color: var(--prim-100);
  width: min-content;
  margin: 0 !important;
  padding: 7px 0 !important;
  border: 1px solid var(--prim-100) !important;

  &.bottomPadded {
    margin-bottom: 20px !important;
  }

  &.authorized {
    background-color: lightgreen !important;
    border-color: lightgreen !important;

    &.disabled {
      cursor: default;
      background-color: var(--grey-50) !important;
      border-color: var(--grey-50) !important;
    }
  }
}

.integrationDescription {
  color: var(--grey-70);
  font-size: 14px;
  font-style: normal;
  line-height: 20px;

  a {
    display: block;
  }
}

.connectedStatusText {
  color: var(--green);
}

.disconnectedStatusText {
  color: var(--grey-70);
}

.tagsLabel {
  text-transform: uppercase;
  margin: 2rem 0 0.5rem;
}

.connectButtonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  margin: 1rem 0;
}

// IntegrationsSidebar Ends

.productContainer {
  display: grid;
  grid-template-columns: 3fr 2fr 3fr;
  gap: 8px;
  align-items: center;

  &.boxContainer {
    gap: 0 8px;
    grid-template-columns: 2fr 3fr;
    border: 2px solid var(--grey-20);
    border-radius: 12px;
    padding: 8px 16px 16px;
    margin-top: 10px;

    & .fieldHeaderText {
      margin-top: 0;
    }
  }
}

.twoColumnContainer {
  display: grid;
  grid-template-columns: 5fr 3fr;
  gap: 8px;

  &.rightWide {
    grid-template-columns: 3fr 5fr;
  }

  .fieldSelectorContainer {
    display: flex;
  }
}

.customPropertyDelete {
  margin-top: 7px;
  cursor: pointer;
}

.fieldSelector {
  width: 120px;
  margin-left: 0 !important;
}

.customPropertyAdd {
  cursor: pointer;
  margin-left: 4px;
  margin-bottom: 10px;
  margin-top: 5px;
  width: 17px;
}

.fieldHeaderText {
  font-size: 12px;
  font-weight: 600;
  color: var(--grey-80);
  text-transform: uppercase;
  display: flex;
  align-items: center;
  height: 30px;
  margin-top: 10px;
}

.helperText {
  font-size: 11.5px;
  color: var(--grey-80);
  margin-bottom: 8px;
}

// Plaid Ends
